import { SET_PENDING_NAVIGATION, CLEAR_PENDING_NAVIGATION } from '../actions/navigationActions';

const initialState = {
  pendingNavigation: null,
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_NAVIGATION:
      return {
        ...state,
        pendingNavigation: action.payload,
      };
    case CLEAR_PENDING_NAVIGATION:
      return {
        ...state,
        pendingNavigation: null,
      };
    default:
      return state;
  }
};

export default navigationReducer;
