import * as actions from 'Actions';
import { deliveryTypes } from 'Constants/displayConstants';
import { parseQuery } from 'HelperFunctions/QueryString';
import { formatPhoneNumber } from 'HelperFunctions/general';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { Company } from 'Utils/SvgIcons';
import classNames from 'classnames';
import React from 'react';
import Imgix from 'react-imgix';
import { FormattedDate, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import originalTerminologies from '../../helper_functions/originalTerminologies';
import PrintRentalBundle from './PrintRentalBundle';
import PrintRentalItem from './PrintRentalItem';
import RentalBilling from './RentalBilling';

class PrintSummary extends React.Component {
  componentDidMount() {
    const { fetchRentalIfNeeded } = this.props;
    fetchRentalIfNeeded(this.props.match.params.id);
  }

  renderDeliveryInfo() {
    const { rental } = this.props;
    const {
      delivery,
      deliveryTrackingNumber,
      returnTrackingNumber,
      schedule,
    } = rental;
    if (delivery.delivery_type === 'customer_pick_up') {
      return null;
    } else if (delivery.delivery_type === 'ship') {
      return [
        <tr className='top'>
          <td>Delivery</td>
          <td>
            <div>
              Tracking #: <p>{deliveryTrackingNumber}</p>
              <h6>Estimated</h6>
              <div>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.startWindowFinish}
                />{' '}
                <FormattedTime
                  value={schedule.startWindowFinish}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
              </div>
            </div>
          </td>
        </tr>,
        <tr className='top'>
          <td>Return</td>
          <td>
            <div>
              Tracking #: <p>{returnTrackingNumber}</p>
              <h6>Estimated</h6>
              <div>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.endWindowBeginning}
                />{' '}
                <FormattedTime
                  value={schedule.endWindowBeginning}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
              </div>
            </div>
          </td>
        </tr>,
      ];
    } else {
      return (
        <tr className='top'>
          <td>
            Delivery
            <br />
            Address
          </td>
          <td>
            <div>
              {delivery.delivery_address_location_name && (
                <div>{delivery.delivery_address_location_name}</div>
              )}
              <div>{delivery.delivery_address_street_address_1}</div>
              {delivery.delivery_address_street_address_2 && (
                <div># {delivery.delivery_address_street_address_2}</div>
              )}
              <div>
                {delivery.delivery_address_city},{' '}
                {delivery.delivery_address_locale}{' '}
                {delivery.delivery_address_postal_code},{' '}
                {delivery.delivery_address_country}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderDimensions(product) {
    return (
      <div>
        {product.length || product.width || product.height ? (
          <div>
            <span>
              {product.lengthFt ? `${product.lengthFt}'` : ''}{' '}
              {product.length ? `${product.length}"` : ''}
            </span>
            <span>x</span>
            <span>
              {product.widthFt ? `${product.widthFt}'` : ''}{' '}
              {product.width ? `${product.width}"` : ''}
            </span>
            <span>
              {product.heightFt ? `${product.heightFt}'` : ''}{' '}
              {product.height ? `${product.height}"` : ''}
            </span>
          </div>
        ) : (
          <div>
            <span>Not specified</span>
          </div>
        )}
        {product.weight && (
          <p>
            {product.weight}
            lbs
          </p>
        )}
      </div>
    );
  }

  renderCompanyContact = (companyIsPrimary) => {
    const { companyRentalRelationship } = this.props.rental;
    return (
      <div className='rentalCustomer ' style={{ marginBottom: 30 + 'px' }}>
        <h2 className='subHeading'>Company</h2>
        {companyIsPrimary && <div className='caption'>Primary</div>}
        <div>
          <strong>{companyRentalRelationship.company.name}</strong>
        </div>
        {companyRentalRelationship.company.cell && (
          <div>{formatPhoneNumber(companyRentalRelationship.company.cell)}</div>
        )}
        {companyRentalRelationship.company.fax && (
          <div>{formatPhoneNumber(companyRentalRelationship.company.fax)}</div>
        )}
        <div>{companyRentalRelationship.company.email}</div>
        {(companyRentalRelationship.company.streetAddress1 ||
          companyRentalRelationship.company.streetAddress2 ||
          companyRentalRelationship.company.postalCode ||
          companyRentalRelationship.company.locale ||
          companyRentalRelationship.company.city ||
          companyRentalRelationship.company.country) && (
          <div>
            <div>{companyRentalRelationship.company.streetAddress1}</div>
            <div>{companyRentalRelationship.company.streetAddress2}</div>
            <div>
              {companyRentalRelationship.company.city},{' '}
              {companyRentalRelationship.company.locale}{' '}
              {companyRentalRelationship.company.postalCode},{' '}
              {companyRentalRelationship.company.country}
            </div>
          </div>
        )}
      </div>
    );
  };

  renderPrimaryCustomerContact = () => {
    const { customerRentalRelationships } = this.props.rental;
    return customerRentalRelationships.map((customerRentalRelationship, i) => {
      const info = customerRentalRelationship.client.finalInfo
        ? customerRentalRelationship.client.finalInfo
        : customerRentalRelationship.client;
      if (customerRentalRelationship.isPrimaryContact)
        return (
          <div className='rentalCustomer' key={i}>
            <div className='caption'>Primary</div>
            <strong>{info.name}</strong>
            {info.office && <div>{formatPhoneNumber(info.office)}</div>}
            {info.cell && <div>{formatPhoneNumber(info.cell)}</div>}
            {info.fax && <div>{formatPhoneNumber(info.fax)}</div>}
            <div>{info.email}</div>
            {(info.streetAddress1 ||
              info.streetAddress2 ||
              info.postalCode ||
              info.locale ||
              info.city ||
              info.country) && (
              <div>
                <div>{info.streetAddress1}</div>
                <div>{info.streetAddress2}</div>
                <div>
                  {info.city}, {info.locale} {info.postalCode}, {info.country}
                </div>
              </div>
            )}
          </div>
        );
    });
  };

  renderRegularCustomerContacts = () => {
    const { customerRentalRelationships } = this.props.rental;
    return customerRentalRelationships.map((customerRentalRelationship, i) => {
      const info = customerRentalRelationship.client.finalInfo
        ? customerRentalRelationship.client.finalInfo
        : customerRentalRelationship.client;
      if (!customerRentalRelationship.isPrimaryContact)
        return (
          <div className='rentalCustomer' key={i}>
            <strong>{info.name}</strong>
            {info.office && <div>{formatPhoneNumber(info.office)}</div>}
            {info.cell && <div>{formatPhoneNumber(info.cell)}</div>}
            {info.fax && <div>{formatPhoneNumber(info.fax)}</div>}
            <div>{info.email}</div>
            {(info.streetAddress1 ||
              info.streetAddress2 ||
              info.postalCode ||
              info.locale ||
              info.city ||
              info.country) && (
              <div>
                <div>{info.streetAddress1}</div>
                <div>{info.streetAddress2}</div>
                <div>
                  {info.city}, {info.locale} {info.postalCode}, {info.country}
                </div>
              </div>
            )}
          </div>
        );
    });
  };

  customerRendering = () => {
    return (
      <div>
        <h2 className='subHeading'>Customer</h2>
        {this.renderPrimaryCustomerContact()}
        {this.renderRegularCustomerContacts()}
      </div>
    );
  };

  renderCompanyAndCustomer = () => {
    const {
      companyRentalRelationship,
      customerRentalRelationships,
    } = this.props.rental;

    if (
      companyRentalRelationship &&
      companyRentalRelationship.isPrimaryContact &&
      customerRentalRelationships.length
    ) {
      return (
        <div>
          {this.renderCompanyContact(true)}
          {this.customerRendering()}
        </div>
      );
    }

    if (
      companyRentalRelationship &&
      companyRentalRelationship.isPrimaryContact &&
      !customerRentalRelationships.length
    ) {
      return <div>{this.renderCompanyContact(true)}</div>;
    }

    if (
      companyRentalRelationship &&
      !companyRentalRelationship.isPrimaryContact
    ) {
      return (
        <div>
          {this.customerRendering()}
          {this.renderCompanyContact(false)}
        </div>
      );
    }

    if (!companyRentalRelationship) {
      return this.customerRendering();
    }
  };

  render() {
    const { rental, location, itemNotesView } = this.props;
    const {
      customerLocationRelationships,
      companyRentalRelationship,
      schedule,
      delivery,
      items,
      accessories,
      addons,
      bundles,
      staffs,
      notes,
    } = rental;

    let { tileView, descriptions, dimensions } = parseQuery(
      window.location.search
    );
    tileView = tileView === 'true';
    const descriptionsAllowed = descriptions === 'true';
    const dimensionsAllowed = dimensions === 'true';
    const customerPickUp = delivery.delivery_type === 'customer_pick_up';

    return (
      <div className='printWrapper'>
        <div className='content'>
          <h1>RENTAL SUMMARY</h1>
          <div className='header'>
            <div className='logo'>
              <figure>
                {location.picture === null ? (
                  <Company />
                ) : (
                  <Imgix
                    src={location.picture.imgixUrl}
                    alt={location.name}
                    width={510}
                    height={288}
                  />
                )}
              </figure>
              <div className='businessPrint'>
                <h2>{location.name}</h2>
                {location.physicalAddress.streetAddress1}
                {location.physicalAddress.streetAddress2 &&
                  `#${location.physicalAddress.streetAddress2}`}
                <br />
                {location.physicalAddress.city},{' '}
                {location.physicalAddress.locale}{' '}
                {location.physicalAddress.postalCode},{' '}
                {location.physicalAddress.country}
              </div>
            </div>

            <div className='title'>
              <h4>
                {rental.customId && (
                  <div style={{ textAlign: 'right' }}>#{rental.token}</div>
                )}
                Rental{' '}
                <span>
                  {' '}
                  {rental.customId ? (
                    <span style={{ float: 'right' }}>
                      {originalTerminologies().customIdSingular}: #
                      {rental.customId}
                    </span>
                  ) : (
                    <span style={{ float: 'right' }}>#{rental.token}</span>
                  )}
                </span>
              </h4>
              <h1>{rental.name}</h1>

              <div className='dates'>
                <label>Order Start</label>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.eventStartDate}
                />
                <FormattedTime
                  value={schedule.eventStartTime}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
                <label>Order End</label>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.eventEndDate}
                />
                <FormattedTime
                  value={schedule.eventEndTime}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
              </div>
            </div>
          </div>

          <div className='details'>
            <section className='deliveryPrint'>
              <h4>Delivery Info</h4>
              <table className='grid'>
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>{deliveryTypes[delivery.delivery_type]}</td>
                  </tr>
                  {this.renderDeliveryInfo()}
                </tbody>
              </table>
            </section>

            <section className='checkInPrint'>
              <h4>
                {customerPickUp ? 'Pick Up / Drop off' : 'Drop off / Pick Up'}
              </h4>
              <table className='grid'>
                <tbody>
                  <tr className='top'>
                    <td>{customerPickUp ? 'Pick Up' : 'Drop off'}</td>
                    <td>
                      {rental.schedule.eventStartSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.startDate}
                          />{' '}
                          <FormattedTime
                            value={schedule.startTime}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                      <div className='caption'>Through</div>
                      {rental.schedule.eventStartSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.startWindowFinish}
                          />{' '}
                          <FormattedTime
                            value={schedule.startWindowFinish}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                    </td>
                  </tr>
                  <tr className='top'>
                    <td>{customerPickUp ? 'Drop off' : 'Pick Up'}</td>
                    <td>
                      {rental.schedule.eventEndSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.endWindowBeginning}
                          />{' '}
                          <FormattedTime
                            value={schedule.endWindowBeginning}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                      <div className='caption'>Through</div>
                      {rental.schedule.eventEndSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.endTime}
                          />{' '}
                          <FormattedTime
                            value={schedule.endTime}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {rental.hasFlip && (
                <div>
                  <h4>Flip</h4>
                  <table className='grid'>
                    <tbody>
                      <tr>
                        <td>Flip</td>
                        <td className='medSm'>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={rental.flipDateTime}
                          />
                        </td>
                        <td>
                          <FormattedTime value={rental.flipDateTime} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {schedule.shelfEnabled && (
                <div>
                  <h4>Off-the-Shelf Time</h4>
                  <table className='grid'>
                    <tbody>
                      <tr>
                        <td>Off-Shelf</td>
                        <td className='medSm'>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.offShelfAt}
                          />
                        </td>
                        <td>
                          <FormattedTime value={schedule.offShelfAt} />
                        </td>
                      </tr>
                      <tr>
                        <td>On-Shelf</td>
                        <td>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.onShelfAt}
                          />
                        </td>
                        <td>
                          <FormattedTime value={schedule.onShelfAt} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </section>

            <section className='customerPrint'>
              <h4>Contact Info</h4>

              {this.renderCompanyAndCustomer()}
            </section>
          </div>

          <section className='items'>
            {items.length > 0 && (
              <div>
                <h4>Items</h4>
                <table
                  className={classNames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <thead>
                    <tr>
                      {tileView && <th className='image' />}
                      <th className='sm center'>Qty</th>
                      <th>Item</th>
                      <th className='md center'>Rate</th>
                      <th className='sm center'>Price</th>
                      {descriptionsAllowed && <th />}
                      {dimensionsAllowed && <th />}
                    </tr>
                  </thead>

                  {items.map((item, index) => (
                    <PrintRentalItem
                      key={index}
                      index={index}
                      item={item}
                      tileView={tileView}
                      descriptionsAllowed={descriptionsAllowed}
                      dimensionsAllowed={dimensionsAllowed}
                      itemNotesView={itemNotesView}
                    />
                  ))}
                </table>
              </div>
            )}

            {accessories.length > 0 && (
              <div>
                <h4>Accessories</h4>
                <table
                  className={classNames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <thead>
                    <tr>
                      <th className='action' />
                      {tileView && <th className='image' />}
                      <th className='sm center'>Qty</th>
                      <th>Item</th>
                      {descriptionsAllowed && <th />}
                      {dimensionsAllowed && <th />}
                    </tr>
                  </thead>
                  {accessories.map((item, index) => (
                    <PrintRentalItem
                      key={index}
                      index={index}
                      item={item}
                      type='accessory'
                      tileView={tileView}
                      descriptionsAllowed={descriptionsAllowed}
                      dimensionsAllowed={dimensionsAllowed}
                    />
                  ))}
                </table>
              </div>
            )}

            {addons.length > 0 && (
              <div>
                <h4>Purchases</h4>
                <table
                  className={classNames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <thead>
                    <tr>
                      <th className='action' />
                      {tileView && <th className='image' />}
                      <th className='sm center'>Qty</th>
                      <th>Item</th>
                      {descriptionsAllowed && <th />}
                      {dimensionsAllowed && <th />}
                    </tr>
                  </thead>
                  {addons.map((item, index) => (
                    <PrintRentalItem
                      key={index}
                      index={index}
                      item={item}
                      type='addOn'
                      tileView={tileView}
                      descriptionsAllowed={descriptionsAllowed}
                      dimensionsAllowed={dimensionsAllowed}
                    />
                  ))}
                </table>
              </div>
            )}

            {bundles.length > 0 && (
              <div>
                <h4>Bundles</h4>
                <table
                  className={classNames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <thead>
                    <tr>
                      <th className='action' />
                      {tileView && <th className='image' />}
                      <th className='sm center'>Qty</th>
                      <th>Item</th>
                      <th className='sm center'>Price</th>
                    </tr>
                  </thead>
                  {bundles.map((item, index) => (
                    <PrintRentalBundle
                      key={index}
                      index={index}
                      item={item}
                      tileView={tileView}
                      descriptionsAllowed={descriptionsAllowed}
                      dimensionsAllowed={dimensionsAllowed}
                    />
                  ))}
                </table>
              </div>
            )}

            {staffs.length > 0 && (
              <div className='staff'>
                <h4>Staff</h4>
                <table className='grid'>
                  <thead>
                    <tr>
                      <th className='sm'>Qty</th>
                      <th>Staff</th>
                      <th>Time In</th>
                      <th>Time Out</th>
                      <th className='md'>Rate</th>
                      <th className='sm'>Price</th>
                    </tr>
                  </thead>
                </table>
                <table className='grid'>
                  {staffs.map((staff, index) => (
                    <tbody key={`${index}s`}>
                      <tr>
                        <td className='sm'>{staff.size}</td>
                        <td>{staff.name}</td>
                        <td>
                          <FormattedDate
                            value={staff.staffSchedule.inTime}
                            month='numeric'
                            year='numeric'
                            day='numeric'
                          />{' '}
                          <FormattedTime value={staff.staffSchedule.inTime} />
                        </td>
                        <td>
                          <FormattedDate
                            value={staff.staffSchedule.outTime}
                            month='numeric'
                            year='numeric'
                            day='numeric'
                          />{' '}
                          <FormattedTime value={staff.staffSchedule.outTime} />
                        </td>
                        <td className='md'>
                          <CurrencyLabel value={staff.cost / staff.size} />
                        </td>
                        <td className='sm'>
                          <CurrencyLabel value={staff.cost} />
                        </td>
                      </tr>
                      {staff.rentalStaffMembers.map((rentalStaffMember) => (
                        <tr key={rentalStaffMember.id} className='noBorder'>
                          <td className='sm'>{rentalStaffMember.size}</td>
                          <td>{rentalStaffMember.name}</td>
                          <td>
                            {rentalStaffMember.staffMember.cell &&
                              rentalStaffMember.staffMember.cell.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                '$1-$2-$3'
                              )}
                          </td>
                          <td colSpan='3'>
                            <FormattedDate
                              value={rentalStaffMember.staffSchedule.inTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime
                              value={rentalStaffMember.staffSchedule.inTime}
                            />{' '}
                            <FormattedDate
                              value={rentalStaffMember.staffSchedule.outTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime
                              value={rentalStaffMember.staffSchedule.outTime}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
            )}
          </section>
          <RentalBilling
            rental={rental}
            ownerView={true}
            secondary={true}
          />
          {notes && notes.length > 0 && (
            <section className='notes'>
              <h4>Notes</h4>
              {notes.map((note) => (
                <div
                  key={note.id}
                  style={{ display: note.visibleToCustomer ? 'unset' : 'none' }}
                >
                  <h6>
                    <FormattedDate
                      value={note.createdAt}
                      year='numeric'
                      month='short'
                      day='numeric'
                    />
                    {note.employee && (
                      <span className='highlight'>
                        {note.employee.firstName} {note.employee.lastName}
                      </span>
                    )}
                  </h6>
                  <div
                    className='editedText'
                    dangerouslySetInnerHTML={{
                      __html: note.message,
                    }}
                  />
                </div>
              ))}
            </section>
          )}

          <div className='printSignature'>
            <label className='line lg'>Signature</label>
            <label className='line'>Date</label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { rental } = state.rental;
  const { location } = state.location;
  const { itemNotesView } = state.products;
  return { user, rental, location, itemNotesView };
};

export default connect(mapStateToProps, actions)(PrintSummary);
