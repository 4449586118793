export const SET_PENDING_NAVIGATION = 'SET_PENDING_NAVIGATION';
export const CLEAR_PENDING_NAVIGATION = 'CLEAR_PENDING_NAVIGATION';

export const setPendingNavigation = (pendingNavigation) => ({
  type: SET_PENDING_NAVIGATION,
  payload: pendingNavigation,
});

export const clearPendingNavigation = () => ({
  type: CLEAR_PENDING_NAVIGATION,
});
